import * as React from "react";
import { Link } from "gatsby";

import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

const NotFoundPage = () => {
  return (
    <Page>
      <TopBar />
      <Section>
        <SectionHeading style={headingStyles}>Page not found</SectionHeading>
        <p style={paragraphStyles}>
          Sorry 😔, we couldn't find what you were looking for.
        </p>
      </Section>
      <Footer />
    </Page>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
